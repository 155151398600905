<template>
  <div class="matchmakerCase">
    <div class="matchmakerCase-box">
      <div class="box">
        <v-h5Mtml :content="postData.content"></v-h5Mtml>
      </div>
    </div>
  </div>
</template>

<script>
import { getDsfContentInfoListUrl } from "./api";
export default {
  name: "matchmakerCase",
  data() {
    return {
      postData: {
        content: "",
      },
    };
  },
  computed: {
    communityId() {
      return this.$store.state.communityId;
    },
    userId() {
      return this.$store.state.userId;
    },
    roomId() {
      return this.$store.state.houseId;
    },
    tenantId() {
      return this.$store.state.tenantId;
    },
  },
  created() {},
  mounted() {
    this.getList();
  },
  methods: {
    async getList() {
      let params = {
        curPage: 1,
        pageSize: 99,
        status: 1,
      };
      let res = await this.$axios.get(`${getDsfContentInfoListUrl}`, {
        params: params,
      });
      if (res.code === 200) {
        let oList = res.data && res.data.records;
        if (oList && oList.length != 0) {
          oList.forEach((element) => {
            if (element.type == "案列展示") {
              this.postData = element;
            }
          });
        }
      } else {
      }
    },
  },
};
</script>

<style lang="less" scoped>
.matchmakerCase {
  min-height: 100vh;
  background: url("./img/caseBack.png") no-repeat;
  background-size: 100% 100%;
  position: relative;
  .matchmakerCase-box {
    position: absolute;
    width: 90%;
    height: 90%;
    background: url("./img/case1.png") no-repeat;
    background-size: 100% 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    padding: 80px;
    box-sizing: border-box;
    .box {
      overflow-y: auto;
    }
  }
}
</style>
